import axios from "axios";
import { AuditLogEntry, CreateWebhookCommand, EventType, EventTypeCreateCommand, EventTypeUpdateCommand, LogEntry, PagedResult, PersonalLogEntry, ProfileData, ResendManyCommand, SourceItem, SubscriptionMethod, TsKunde, UnsubscribeFromAllCommand, UpdateProfileCommand, UpdateWebhookCommand, Webhook, WebhookLogEntry, WebhookSubscriber } from "./models";

//let baseUrl = "https://varslingapi.tradesolution.no/api";
let baseUrl = "https://localhost:5000/api";

if (window.location.hostname === "varsling.tradesolution.no") {
    baseUrl = "https://varslingapi.tradesolution.no/api";
} else if (window.location.hostname === "varsling-test.tradesolution.no") {
    baseUrl = "https://varslingapi-test.tradesolution.no/api";
} else if (window.location.hostname === "varsling-dev.tradesolution.no") {
    baseUrl = "https://varslingapi-dev.tradesolution.no/api";
}

export interface ApiResult<T> {
    data: T | undefined;
    status: number;
    error: string | undefined;
    success: boolean;
}

async function post<TResponse>(url: string, payload?: any): Promise<ApiResult<TResponse>> {
    try {
        const result = await axios.post(url, payload);
        return {
            status: result.status,
            success: result.status >= 200 && result.status < 300,
            error: undefined,
            data: result.data as TResponse
        }
    } catch (error) {
        return {
            status: 0,
            success: false,
            error: error,
            data: undefined
        }
    }
}

async function put<TResponse>(url: string, payload?: any): Promise<ApiResult<TResponse>> {
    try {
        const result = await axios.put(url, payload);
        return {
            status: result.status,
            success: result.status >= 200 && result.status < 300,
            error: undefined,
            data: result.data as TResponse
        }
    } catch (error) {
        return {
            status: 0,
            success: false,
            error: error,
            data: undefined
        }
    }
}

async function del<TResponse>(url: string): Promise<ApiResult<TResponse>> {
    try {
        const result = await axios.delete(url);
        return {
            status: result.status,
            success: result.status >= 200 && result.status < 300,
            error: undefined,
            data: result.data as TResponse
        }
    } catch (error) {
        return {
            status: 0,
            success: false,
            error: error,
            data: undefined
        }
    }
}

const toQuerystring = (keyValues: { key: string, value: string }[]) => {
    return keyValues.map(kv => `${kv.key}=${kv.value}`).join("&");
}

const SupernovaApi = {
    getEventLog: async (source?: string, q?: string, kunUleverte?: boolean, fromDate?: Date, take: number = 40): Promise<LogEntry[]> => {
        let url = `${baseUrl}/eventlog`;

        const keyValues = [];
        if (source) {
            keyValues.push({ key: "sources", value: source });
        }

        if (q) {
            keyValues.push({ key: "searchValue", value: q });
        }

        if (kunUleverte) {
            keyValues.push({ key: "undelivered", value: "true" });
        }

        if (fromDate) {
            const utcDate = new Date(fromDate);
            const utcTimestamp = utcDate.getTime();
            keyValues.push({ key: "from", value: utcTimestamp.toString() });
        }

        keyValues.push({ key: "take", value: take.toString() });
        if (keyValues.length > 0) {
            url += `?${toQuerystring(keyValues)}`;
        }

        return (await axios.get(url)).data;
    },
    getSources: async (): Promise<SourceItem[]> => {
        return (await axios.get(`${baseUrl}/sources`)).data;
    },
    getMySources: async (): Promise<SourceItem[]> => {
        return (await axios.get(`${baseUrl}/sources/mine`)).data;
    },
    getWebhookSubscriberForEventtype: async (eventTypeId: string): Promise<WebhookSubscriber[]> => {
        return (await axios.get(`${baseUrl}/eventtypes/${eventTypeId}/webhooksubscribers`)).data;
    },
    getPersonalSubscriberForEventtype: async (eventTypeId: string): Promise<ProfileData[]> => {
        return (await axios.get(`${baseUrl}/eventtypes/${eventTypeId}/personalsubscribers`)).data;
    },
    getPersonalSubscriberForEventtypeCount: async (eventTypeId: string): Promise<number> => {
        return (await axios.get(`${baseUrl}/eventtypes/${eventTypeId}/personalsubscribers/count`)).data;
    },
    getPersonalSubscribers: async (tsKundeId?: string, q?: string, take: number = 30): Promise<PagedResult<ProfileData>> => {
        let url = `${baseUrl}/personalsubscribers`;

        const keyValues = [];

        if (tsKundeId) {
            keyValues.push({ key: "tsKundeId", value: tsKundeId });
        }

        if (q) {
            keyValues.push({ key: "q", value: q });
        }

        keyValues.push({ key: "take", value: take.toString() });
        if (keyValues.length > 0) {
            url += `?${toQuerystring(keyValues)}`;
        }

        return (await axios.get(url)).data;
    },
    searchTsKundeByName: async (query: string): Promise<TsKunde[]> => {
        const response = await axios.get(`${baseUrl}/TsKunder/Search/${query}`);
        return response.data;
    },
    eventTypes: {
        getEventTypes: async (): Promise<EventType[]> => {
            return (await axios.get(`${baseUrl}/eventtypes`)).data;
        },
        getMyEventTypes: async (): Promise<EventType[]> => {
            return (await axios.get(`${baseUrl}/eventtypes/mine`)).data;
        },
        getEventType: async (id: string): Promise<EventType> => {
            return (await axios.get(`${baseUrl}/eventtypes/${id}`)).data;
        },
        addEventType: async (command: EventTypeCreateCommand): Promise<ApiResult<EventType>> => {
            return await post(`${baseUrl}/eventtypes`, command);
        },
        updateEventType: async (command: EventTypeUpdateCommand): Promise<ApiResult<EventType>> => {
            return await put(`${baseUrl}/eventtypes/${command.id}`, command);
        },
        deleteEventType: async (id: string): Promise<ApiResult<void>> => {
            return await del(`${baseUrl}/eventtypes/${id}`);
        },
        toggleRecommendedForDelayedEmailDelivery: async (id: string): Promise<ApiResult<EventType>> => {
            return await put(`${baseUrl}/eventtypes/${id}/toggleRecommendedForDelayedEmailDelivery`);
        },
        toggleRecommendedForImidiateEmailDelivery: async (id: string): Promise<ApiResult<EventType>> => {
            return await put(`${baseUrl}/eventtypes/${id}/toggleRecommendedForImidiateEmailDelivery`);
        },
        toggleRecommendedForSmsDelivery: async (id: string): Promise<ApiResult<EventType>> => {
            return await put(`${baseUrl}/eventtypes/${id}/toggleRecommendedForSmsDelivery`);
        },
        toggleNotAvailableForSourceSubscriptions: async (id: string): Promise<ApiResult<EventType>> => {
            return await put(`${baseUrl}/eventtypes/${id}/toggleNotAvailableForSourceSubscriptions`);
        }
    },
    webhooks: {
        get: async (id: string): Promise<Webhook> => {
            return (await axios.get(`${baseUrl}/webhooks/${id}`)).data;
        },
        getWebhooks: async (tsKundeId?: string): Promise<Webhook[]> => {
            let url = `${baseUrl}/webhooks`;
            if (tsKundeId) {
                url += `?tsKundeId=${tsKundeId}`;
            }
            return (await axios.get(url)).data;
        },
        addWebhook: async (command: CreateWebhookCommand): Promise<ApiResult<Webhook>> => {
            return await post(`${baseUrl}/webhooks`, command);
        },
        updateWebhook: async (command: UpdateWebhookCommand): Promise<ApiResult<Webhook>> => {
            return await put(`${baseUrl}/webhooks/${command.id}`, command);
        },
        deleteWebhook: async (id: string): Promise<ApiResult<Webhook>> => {
            return await del(`${baseUrl}/webhooks/${id}`);
        },
        getLog: async (webhookId: string, undelivered: boolean, fromDate?: Date, source?: string, searchableText?: string, take: number = 40): Promise<WebhookLogEntry[]> => {
            let url = `${baseUrl}/webhooks/${webhookId}/messages?take=${take}&skip=0&undelivered=${undelivered}`;
            if (source) {
                url += `&sources=${source}`;
            }
            if(searchableText) {
                url += `&searchableText=${encodeURIComponent(searchableText)}`;
            }
            if (fromDate) {
                const utcDate = new Date(fromDate);
                const utcTimestamp = utcDate.getTime();
                url += `&fromDate=${utcTimestamp.toString()}`;
            }

            return (await axios.get(url)).data;
        },
        resend: async (webhookId: string, eventId: string): Promise<ApiResult<any>> => {
            const url = `${baseUrl}/webhooks/${webhookId}/resend`;
            return await post(url, { eventId: eventId });
        },
        resendMany: async (webhookId: string, command: ResendManyCommand): Promise<ApiResult<number>> => {
            const url = `${baseUrl}/webhooks/${webhookId}/resendMany`;
            return await post(url, command);
        },
        test: async (webhookId: string, eventTypeId: string, payload: object, displayMessage: string): Promise<ApiResult<any>> => {
            const url = `${baseUrl}/webhooks/${webhookId}/test`;
            return post(url, { eventTypeId: eventTypeId, webhookId: webhookId, payload: payload, displayMessage: displayMessage });
        },
        auditLog: async (webhookId: string): Promise<AuditLogEntry[]> => {
            const url = `${baseUrl}/auditlog/${webhookId}?count=40&skip=0`;
            return (await axios.get(url)).data;
        },
        unsubscribeToEventType: async (webhookId: string, eventTypeId: string): Promise<ApiResult<any>> => {
            const url = `${baseUrl}/webhooks/${webhookId}/unsubscribe?eventTypeId=${eventTypeId}`;
            return put(url);
        },        
        subscribeToEventTypes: async (webhookId: string, eventTypeIds: string[]): Promise<ApiResult<any>> => {
            const url = `${baseUrl}/webhooks/${webhookId}/Subscriptions`;
            const command = {
                id: webhookId,
                subscribeToEventTypeIds: eventTypeIds
            };
            return put(url, command);
        },
    },
    profile: {
        getById: async (id: string): Promise<ProfileData> => {
            return (await axios.get(`${baseUrl}/PersonalSubscribers/${id}`)).data;
        },
        getByTsKunde: async (tsKundeId: string): Promise<ProfileData> => {
            return (await axios.get(`${baseUrl}/PersonalSubscribers/bytskunde/${tsKundeId}`)).data;
        },
        update: async (command: UpdateProfileCommand): Promise<ApiResult<ProfileData>> => {
            const url = `${baseUrl}/PersonalSubscribers/${command.id}/updateprofile`;
            return await put(url, command);
        },
        enableEmailNotifications: async (profile: ProfileData): Promise<ApiResult<any>> => {
            const url = `${baseUrl}/PersonalSubscribers/${profile.id}/receiveEmailNotifications`;
            const payload = {
                emailAddress: profile.emailAddress,
                name: profile.name
            }
            return await post(url, payload);
        },
        IWantItNow: async (profile: ProfileData): Promise<ApiResult<any>> => {
            const url = `${baseUrl}/PersonalSubscribers/${profile.id}/IWantItNow`;
            return await put(url);
        },
        disableEmailNotifications: async (profile: ProfileData): Promise<ApiResult<any>> => {
            const url = `${baseUrl}/PersonalSubscribers/${profile.id}/RemoveEmail`;
            return await put(url, {});
        },        
        subscribeToEvent: async (subscriberId: string, eventTypeId: string, method: SubscriptionMethod): Promise<ApiResult<ProfileData>> => {
            let endpoint;

            switch (method) {
                case SubscriptionMethod.Sms:
                    endpoint = `SubscribeSmsToEvent`;
                    break;
                case SubscriptionMethod.Email:
                    endpoint = `SubscribeEmailToEvent`;
                    break;
                case SubscriptionMethod.EmailImidiate:
                    endpoint = `SubscribeImidiateEmailToEvent`;
                    break;
                case SubscriptionMethod.Web:
                    endpoint = `SubscribeToEvent`;
                    break;
                default:
                    endpoint = `SubscribeToEvent`;
            }

            const payload = {
                eventTypeId: eventTypeId,
                personalSubscriberId: subscriberId
            }
            return await put(`${baseUrl}/PersonalSubscribers/${subscriberId}/${endpoint}`, payload);
        },
        subscribeToEvents: async (subscriberId: string, eventTypeIds: string[]): Promise<ApiResult<ProfileData>> => {
            const payload = {
                eventTypeIds: eventTypeIds,
                personalSubscriberId: subscriberId
            }
            return await put(`${baseUrl}/PersonalSubscribers/${subscriberId}/SubscribeToEvents`, payload);
        },
        unSubscribeFromEvent: async (subscriberId: string, eventTypeId: string, method: SubscriptionMethod): Promise<ApiResult<ProfileData>> => {
            let endpoint;

            switch (method) {
                case SubscriptionMethod.Sms:
                    endpoint = `UnsubscribeSmsFromEvent`;
                    break;
                case SubscriptionMethod.Email:
                    endpoint = `UnsubscribeEmailFromEvent`;
                    break;
                case SubscriptionMethod.EmailImidiate:
                    endpoint = `UnsubscribeImidiateEmailFromEvent`;
                    break;
                case SubscriptionMethod.Web:
                    endpoint = `UnsubscribeFromEvent`;
                    break;
                default:
                    endpoint = `UnsubscribeFromEvent`;
            }

            const payload = {
                eventTypeId: eventTypeId,
                personalSubscriberId: subscriberId
            }
            return await put<ProfileData>(`${baseUrl}/PersonalSubscribers/${subscriberId}/${endpoint}`, payload);
        },
        delete: async (subscriberId: string): Promise<ApiResult<any>> => {
            return await del(`${baseUrl}/PersonalSubscribers/${subscriberId}`);
        },
        getEventLog: async (subscriberId: string, pageSize: number = 40): Promise<PersonalLogEntry[]> => {
            return (await axios.get(`${baseUrl}/PersonalSubscribers/${subscriberId}/eventlog?pageSize=${pageSize}`)).data;
        },
        getAuditLog: async (subscriberId: string, pageSize: number = 40): Promise<AuditLogEntry[]> => {
            const url = `${baseUrl}/auditlog/${subscriberId}?count=${pageSize}&skip=0`;
            return (await axios.get(url)).data;
        },
        unSubscribeFromEvents: async (subscriberId: string, eventTypeIds: string[]): Promise<ApiResult<ProfileData>> => {
            const url = `${baseUrl}/PersonalSubscribers/${subscriberId}/UnsubscribeFromEvents`;
            const payload = {
                personalSubscriberId: subscriberId,
                eventTypeIds: eventTypeIds
            };
            return await put(url, payload);
        },
        unSubscribeFromAllEvents: async (command: UnsubscribeFromAllCommand): Promise<ApiResult<any>> => {
            const url = `${baseUrl}/Notifications/UnsubscribeFromAll?tsKundeId=${command.tsKundeId}`;
            return await del(url);
        }

    }
}

export default SupernovaApi;